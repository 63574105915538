<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search Requests"
            type="text"
          />
          <b-button
            v-b-modal.addRequestModal
            variant="primary"
          >
            <feather-icon icon="PlusIcon" />
            Add Request
          </b-button>
          <b-form-checkbox
            v-model="hideClosed"
            class="d-flex align-items-center mt-1 justify-content-start"
          >
            Hide Closed Requests
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Show requests raised by"
          label-for="btn-radios-1"
          label-size="sm"
        >
          <b-form-radio-group
            id="btn-radios-1"
            v-model="requester"
            :options="[
              { text: 'Startup & Incubator', value: null },
              { text: 'Incubator', value: 'Incubator' },
              {text:'Startup',value:'Startup'},
            ]"
            buttons
            size="sm"
            button-variant="light"
          />
        </b-form-group>

      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="filteredRows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'title'"
            class="d-flex justify-content-between align-items-center"
          >
            {{ props.row.title }}
            <b-button
              variant="link"
              size="sm"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('requestDetailsModal')"
            >View</b-button>
          </span>
          <span
            v-else-if="props.column.field === 'priority'"
          >
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field])"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
          </span>
          <span
            v-else-if="props.column.field === 'requester' && props.row.raisedbyincubator"
          >
            {{ selectedOrg.title }}
          </span>
          <span
            v-else-if="props.column.field === 'requester' && !props.row.raisedbyincubator"
          >
            {{ props.row.usersAssociationtableByRequester.users_organizationtable.title }}
          </span>

          <span
            v-else-if="props.column.field === 'assigned_to' && props.row.usersAssociationtableByAssignee"
          >
            {{
              props.row.usersAssociationtableByAssignee.role === 'mentor' ? props.row.usersAssociationtableByAssignee.users_customuser.full_name : props.row.usersAssociationtableByAssignee.users_organizationtable.title
            }}
          </span>
          <span
            v-else-if="props.column.field === 'assigned_to' && !props.row.usersAssociationtableByAssignee"
          >
            {{ selectedOrg.title }}
          </span>
          <span
            v-else-if="props.column.field === 'assignee_status'"
          >
            <b-badge
              class="text-capitalize"
              :variant="statusVariant(`${props.formattedRow[props.column.field]}`.toLowerCase())"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
            <b-dropdown
              v-if="!props.row.raisedbyincubator"
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
              class="px-0"
              no-caret
            >
              <template #button-content>
                <span class="align-middle text-nowrap">Update <feather-icon
                  class="align-middle ml-25"
                  icon="ChevronDownIcon"
                /></span>
              </template>
              <b-dropdown-item
                variant="primary"
                :disabled="props.row.assignee_status === 'in-process'"
                @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'in-process')"
              >
                <feather-icon
                  class="mr-50"
                  icon="ClockIcon"
                />
                <span>In-Process</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="success"
                :disabled="props.row.assignee_status === 'resolved'"
                @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'resolved')"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckIcon"
                />
                <span>Resolved</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                :disabled="props.row.assignee_status === 'closed'"
                @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'closed')"
              >
                <feather-icon
                  class="mr-50"
                  icon="XIcon"
                />
                <span>Closed</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>
          <span
            v-else-if="props.column.field === 'status'"
          >
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field].toLowerCase())"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
            <b-dropdown
              v-if="props.row.raisedbyincubator"
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
              class="px-0"
              no-caret
            >
              <template #button-content>
                <span class="align-middle text-nowrap">Update <feather-icon
                  class="align-middle ml-25"
                  icon="ChevronDownIcon"
                /></span>
              </template>
              <b-dropdown-item
                variant="primary"
                :disabled="props.row.status === 'in-process'"
                @click="updateRequestStatus(props.row.requesttable_id, 'in-process')"
              >
                <feather-icon
                  class="mr-50"
                  icon="ClockIcon"
                />
                <span>In-Process</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="success"
                :disabled="props.row.status === 'resolved'"
                @click="updateRequestStatus(props.row.requesttable_id, 'resolved')"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckIcon"
                />
                <span>Resolved</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                :disabled="props.row.status === 'closed'"
                @click="updateRequestStatus(props.row.requesttable_id, 'closed')"
              >
                <feather-icon
                  class="mr-50"
                  icon="XIcon"
                />
                <span>Closed</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="addRequestModal"
        no-close-on-backdrop
        size="lg"
        title="Add Request"
        :ok-title="`${modalStage === 'SELECT_STARTUPS' ? 'Next' : 'Add'}`"
        @ok="addRequest"
        @hidden="resetModal"
      >
        <b-row :class="`${modalStage === 'SELECT_STARTUPS' ? '' : 'd-none'}`">
          <b-col md="12">
            <b-row class="px-1 mb-2">
              <b-col
                md="3"
                :class="tab==='incubatee'?'selected-top-tab':'deselected-top-tab'"
                @click="tab='incubatee';fetchType = 'incubatee';$apollo.queries.startups.refetch()"
              >
                <span>Incubatee</span>
              </b-col>
              <b-col
                md="3"
                :class="tab==='pre-incubatee'?'selected-top-tab':'deselected-top-tab'"
                @click="tab='pre-incubatee';fetchType = 'pre-incubatee';$apollo.queries.startups.refetch()"
              >
                <span>Pre-incubatee</span>
              </b-col>
              <b-col
                md="3"
                :class="tab==='graduate'?'selected-top-tab':'deselected-top-tab'"
                @click="tab='graduate';fetchType = 'graduate';$apollo.queries.startups.refetch()"
              >
                <span>Graduate</span>
              </b-col>
              <b-col
                md="3"
                :class="tab==='startup'?'selected-top-tab':'deselected-top-tab'"
                @click="tab='startup';fetchType = 'startup';$apollo.queries.startups.refetch()"
              >
                <span>Network</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <div class="custom-search d-flex justify-content-start">
              <b-form-group
                class="form-inline"
                label="Search"
                label-size="sm"
              >
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search Startup"
                  type="text"
                />
              </b-form-group>
            </div>
            <vue-good-table
              id="startuptable"
              :columns="startup_columns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="startups"
              :select-options="{ enabled: true }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              @on-selected-rows-change="selectionChanged"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'website'"
                  :id="'row-' + props.row.id"
                >
                  <b-button
                    v-if="props.row.users_organizationtable.url"
                    variant="primary"
                    size="sm"
                    target="_blank"
                    :href="prependHttps(props.row.users_organizationtable.url)"
                  >
                    Visit
                  </b-button>
                </span>
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
        <b-row :class="`${modalStage === 'ADD_REQUEST' ? '' : 'd-none'}`">
          <b-col md="12">
            <h5>Selected Startups:</h5>
            <div style="display:flex; flex-wrap: wrap;">
              <b-badge
                v-for="startup in selectedStartups"
                :key="startup.id"
                variant="primary"
                class="mb-50 mr-50"
              >
                {{ startup.users_organizationtable.title }}
              </b-badge>
              <b-badge
                variant="light-primary"
                class="mb-50 mr-50"
                style="cursor: pointer;"
                @click="modalStage='SELECT_STARTUPS'"
              >Modify</b-badge>
            </div>
            <ValidationObserver
              ref="observer"
            >
              <b-form>
                <b-col md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Request Title"
                    rules="required"
                  >
                    <b-form-group
                      label="Request Title"
                      label-for="requestTitle"
                    >
                      <b-form-input
                        id="requestTitle"
                        v-model="request.title"
                        placeholder="Request Title"
                        type="text"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <b-form-group
                      label="Description"
                      label-for="Description"
                    >
                      <b-form-textarea
                        id="requestDescription"
                        v-model="request.description"
                        max-rows="4"
                        placeholder="Description"
                        rows="2"
                        type="text"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Priority level"
                    rules="required"
                  >
                    <b-form-group
                      label="Priority level"
                      label-for="Priority level"
                    >
                      <v-select
                        v-model="request.priority"
                        :options="['low','medium','high','urgent']"
                        class="text-capitalize"
                        placeholder="Choose Priority level"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        v-if="requests[selectedRow] && requests[selectedRow].hasOwnProperty('description') "
        id="requestDetailsModal"
        hide-footer
        :title="requests[selectedRow].title"
      >
        <div>
          <p>
            {{ requests[selectedRow].description }}
          </p>
          <div
            v-if="requests[selectedRow].comments && requests[selectedRow].comments.length"
          >
            <hr>
            <span
              class="font-medium-1"
            >Comments</span>
            <div
              v-for="(comment, index) in requests[selectedRow].comments"
              :key="index"
              class="border-top mt-1"
            >
              <p class="mt-1 font-weight-bold d-flex justify-content-between text-capitalize">
                {{ comment.created_by }}
                <span><feather-icon icon="CalendarIcon" />
                  {{ comment.created_at }}</span>
              </p>
              <p class="pl-50">
                {{ comment.message }}
              </p>
            </div>
          </div>
        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { mapGetters } from 'vuex'
import { formatDate } from '@core/utils/utils'

extend('required', {
  ...required,
  message: 'This field is required',
})

export default {
  components: {
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      searchTerm: '',
      startupSearchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      tab: 'incubatee',
      columns: [
        {
          label: 'Request Title',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-capitalize',
          filterOptions: {
            enabled: true,
            placeholder: '-- Filter --',
            filterDropdownItems:
                ['Assigned', 'Unassigned', 'In-process', 'Resolved', 'Closed'],
          },
        },
        {
          label: 'Priority',
          field: 'priority',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Requester',
          field: 'requester',
        },
        {
          label: 'Assignee',
          field: 'assigned_to',
        },
        {
          label: 'Assignee Status',
          field: 'assignee_status',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Created on',
          field(row) {
            return formatDate(row.created_at, 'MMM DD, YYYY')
          },
        },
      ],
      startups: [],
      selectedStartups: [],
      fetchType: 'incubatee',
      modalStage: 'SELECT_STARTUPS',
      startup_columns: [
        {
          label: 'Title',
          field(row) {
            return row?.users_organizationtable?.title || '-'
          },
        },
        {
          label: 'Website',
          field: 'website',
        },
        {
          label: 'Industry',
          field(row) {
            return row?.users_organizationtable?.industry || '-'
          },
        },
      ],
      requests: [],
      mutationLoading: false,
      request: {
        title: '',
        description: '',
        priority: '',
        due_date: '',
      },
      assignee: null,
      requester: null,
      hideClosed: true,
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'success',
        medium: 'warning',
        high: 'light-danger',
        urgent: 'danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      let { requests } = this
      if (this.requester) {
        requests = requests.filter(r => {
          if (this.requester === 'Incubator') return r.raisedbyincubator
          if (this.requester === 'Startup') return r.raisedbystartup
          return true
        })
      }
      if (this.hideClosed) {
        requests = requests.filter(r => r.status !== 'closed' && r.assignee_status !== 'closed')
      }
      return requests
    },
  },
  methods: {
    resetModal() {
      this.modalStage = 'SELECT_STARTUPS'
      this.selectedStartups = []
      this.request = {
        title: '',
        description: '',
        priority: '',
      }
    },
    toggleRequester(option) {
      this.requester = option
    },
    selectionChanged(e) {
      this.selectedStartups = e.selectedRows
    },
    addRequest(modalevent) {
      modalevent.preventDefault()
      if (this.modalStage === 'SELECT_STARTUPS') {
        if (this.selectedStartups.length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select at least 1 startup',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        } else {
          this.modalStage = 'ADD_REQUEST'
        }
      } else {
        this.$refs.observer.validate().then(valid => {
          if (!valid) {
            this.showAlert('Please fill in all required fields.', 'danger')
          } else {
            this.mutationLoading = true
            this.$apollo.mutate({
              mutation: gql`
            mutation addRequest($objects: [requesttable_insert_input!]!) {
              insert_requesttable(objects: $objects) {
                affected_rows
              }
            }
          `,
              variables() {
                return {
                  objects: this.selectedStartups.map(el => ({
                    title: this.request.title,
                    description: this.request.description,
                    priority: this.request.priority,
                    incubator_id: this.selectedOrg.orgId,
                    assignee_id: el.id,
                    is_request: true,
                    is_startup: true,
                    status: 'assigned',
                    assignee_status: 'unchecked',
                    raisedbyincubator: true,
                  })),
                }
              },
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Request added successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.request = {
                  title: '',
                  description: '',
                  priority: '',
                }
                this.mutationLoading = false
                this.$apollo.queries.requests.refetch()
                this.$apollo.queries.requests.refetch()
                this.$bvModal.hide('addRequestModal')
                this.modalStage = 'SELECT_STARTUPS'
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error adding request',
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
                this.mutationLoading = false
              })
          }
        })
      }
    },
    updateRequestStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssigneeStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssigneeStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Assignee status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request assignee status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssignee(requestId, assigneeId) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssignee($requestId: Int!, $assigneeId: Int!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_id: $assigneeId, assignee_status: "unchecked"}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          assigneeId,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request assigned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.assignee = null
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating assignee',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
          this.assignee = null
        })
    },
  },
  apollo: {
    requests: {
      query() {
        return gql`
          query GetStartupsRequests($incubatorId: Int!) {
            requesttable(
              where: {
                is_request: { _eq: true },
                is_startup: { _eq: true },
                incubator_id: { _eq: $incubatorId }
              },
              order_by: { requesttable_id: desc }
            ) {
              requesttable_id
              title
              description
              priority
              incubator_id
              assignee_id
              is_request
              is_startup
              status
              assignee_status
              raisedbystartup
              raisedbymentor
              raisedbyincubator
              created_at
              usersAssociationtableByRequester {
                users_organizationtable {
                  title
                }
              }
              usersAssociationtableByAssignee {
                users_organizationtable {
                  title
                }
              }
            }
          }
        `
      },
      variables() {
        return {
          incubatorId: this.selectedOrg.orgId,
        }
      },
      update: data => data.requesttable,
    },
    startups: {
      query() {
        return gql`
      query GetStartups($incubatorId: Int!, $fetchType: String!) {
        users_associationtable(
          where: {
            incubator_id: { _eq: $incubatorId },
            role: { _eq: $fetchType }
          }
        ) {
          id
          organization_id
          users_organizationtable {
            title
            industry
            description
            is_verified
            official_email
            startup_stage
            state
            url
          }
        }
      }
    `
      },
      variables() {
        return {
          incubatorId: this.selectedOrg.orgId,
          fetchType: this.fetchType,
        }
      },
      update: data => data.users_associationtable,
    },
  },
}
</script>

<style>
.selected-top-tab{
  background-color: #7367F0;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
.deselected-top-tab{
  background-color: #F1F1F3;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
</style>
